<template>
  <div>
    <div class="d-flex">
      <v-avatar color="grey lighten-1">
        <v-icon dark>
          mdi-text-box-check
        </v-icon>
      </v-avatar>
      <h1 class="ml-2">{{ referral.program_name }}</h1>
    </div>
    <div class="mt-4 pa-4">
      <div class="text-subtitle-2 caption">Business</div>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-domain
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="referral.business_name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-account
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="referral.business_owner"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>
      <div class="text-subtitle-2 caption">Type</div>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-open-in-new
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="type"></v-list-item-title>

          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>
      <div class="text-subtitle-2 caption">Status</div>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon v-if="status == 'Active'">
              mdi-check
            </v-icon>
            <v-icon v-else>
              mdi-close
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="status"></v-list-item-title>

          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>
      <div class="text-subtitle-2 caption">Notes</div>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-pen
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="referral.notes"></v-list-item-title>

          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>
    </div>
    <DeleteReferral
      title="Delete Referral"
      :message="`Are you sure you want to delete referral?`"
      icon="delete"
      action="Delete"
      @submit="deleteCurrentReferral"/>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import DeleteReferral from '@/components/ConfirmModal.vue';

  export default {
    name: 'ViewReferral',
    components: {
      DeleteReferral
    },
    data() {
      return {
        referral: {
          id: '',
          program_name: '',
          business: '',
          business_name: '',
          businesses_owner: '',
          status: '',
          type: '',
          notes: '',
          contact: ''
        }
      }
    },
    computed: {
      status() {
        return this.referral.status == 0 ? 'Active' : 'Closed';
      },
      type() {
        return this.referral.type == 0 ? 'Internal' : 'External';
      }
    },
    created() {
      this.getCurrentReferral(this.$route.params.id)
    },
    methods: {
      ...mapActions(['getReferral', 'getBusiness', 'deleteReferral']),
      getCurrentReferral(id) {
        this.getReferral({id: id})
        .then((response) => {
          this.referral = Object.assign({}, this.referral, response.data )
          this.getBusiness({id : this.referral.business})
          .then((bresponse) => {
            console.log('response', bresponse)
            this.referral.business_name = bresponse.data.name
            this.referral.business_owner = bresponse.data.owner
          }, (error) => {
            console.log('error', error)
          });
        }, (error) => {
          console.log('error', error)
        });
      },
      deleteCurrentReferral() {
        console.log('this.referral.id', this.referral)
        this.deleteReferral({id: this.referral.id})
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .v-btn.error {
    position: absolute;
    bottom: 16px;
    left: 16px;
  }
</style>
